<template>
  <div class="page" v-if="formInfo">
    <el-form :model="formInfo" label-width="120px">
      <el-card>
        <template slot="header">
          <span class="title">卡券信息</span>
          <div class="button-back">
            <el-button @click="() => {
              $router.go(-1);
            }
              " size="small" class="back_btn">
              <img src="@/assets/back.png" alt="" />
            </el-button>
          </div>
        </template>
        <div class="card-body">
          <el-form-item label="关联销售单号" prop="sell_trade_sn">
            <el-input v-model="formInfo.sell_trade_sn" size="small" style="width: 200px" disabled />
          </el-form-item>

          <el-form-item label="应用类型" prop="app_type_shop_type_flag">
            <el-select size="small" placeholder="请选择应用类型" v-model="formInfo.app_type_shop_type_flag" style="width: 200px"
              disabled>
              <el-option v-for="item in cardTypes" :key="item.value" :value="item.value" :label="item.label" />
            </el-select>
          </el-form-item>

          <!-- 卡券信息 -->
          <card-list :cardData="formInfo.cardList" :disabled="true" :filterColumn="filterColumn" />
          <!-- 卡券信息 -->

          <el-row style="width: 55%">
            <el-col :span="4">
              <el-form-item label="合计总金额">
                <el-input-number size="small" :controls="false" :precision="2" style="width: 200px"
                  v-model="formInfo.total_price" disabled />
              </el-form-item>
            </el-col>
            <el-col :span="4" :offset="11">
              <el-form-item label="优惠后总金额">
                <el-input-number size="small" :controls="false" :precision="2" style="width: 200px"
                  v-model="formInfo.after_discount_price" disabled />
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-card>
      <el-card>
        <template slot="header">
          <span class="title">基础信息</span>
        </template>
        <div class="base-info">
          <el-row type="flex" justify="space-between">
            <el-col :span="6">
              <el-form-item label="客户名称" prop="client_name">
                <el-input size="small" v-model="formInfo.client_name" disabled />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="代理名称">
                <el-input size="small" v-model="formInfo.agent_name" disabled />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="联系人名称">
                <el-input size="small" v-model="formInfo.link_name" disabled />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" justify="space-between">
            <el-col :span="6">
              <el-form-item label="联系电话">
                <el-input size="small" v-model="formInfo.link_phone" disabled />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="销售人员">
                <el-input size="small" v-model="formInfo.sell_name" disabled />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="制单人名称">
                <el-input size="small" v-model="formInfo.make_order_name" disabled />
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="客户地址">
            <el-input size="small" v-model="formInfo.client_addr" disabled />
          </el-form-item>
          <el-form-item label="备注信息">
            <el-input size="small" type="textarea" v-model="formInfo.remark" rows="5" resize="none" disabled />
          </el-form-item>
        </div>
      </el-card>
    </el-form>
  </div>
</template>

<script>
import * as API_saleCard from "@/api/saleCard";
import cardList from "@/views/tools/sale-card-open/components/cardList";
import { createCardTypes } from "@/views/tools/sale-card-open/utils/type";

export default {
  name: "saleRefundDetail",
  components: {
    cardList,
  },
  data () {
    return {
      cardTypes: createCardTypes(),
      filterColumn: ["统一折扣率设置"],
      id: this.$route.query.id,
      formInfo: null,
    };
  },
  mounted () {
    API_saleCard.refundSellNewInfo(this.id).then((res) => {
      const {
        app_type_shop_type_flag,
        client_name,
        agent_name,
        link_name,
        link_phone,
        client_addr,
        sell_name,
        make_order_name,
        total_price,
        after_discount_price,
        remark,
        shop_card_info_list,
        sell_trade_sn,
      } = res;
      this.shop_card_info_list = [...shop_card_info_list];
      const cardList = [...shop_card_info_list]
        .map((shop) => {
          let list = [];
          shop.card_range_list.forEach((item) => {
            list.push({
              ...item,
            });
          });
          return [...list];
        })
        .flat();
      this.formInfo = {
        sell_trade_sn,
        app_type_shop_type_flag,
        client_name,
        agent_name,
        link_name,
        link_phone,
        client_addr,
        sell_name,
        make_order_name,
        total_price,
        after_discount_price,
        remark,
        cardList,
      };
    });
  },
};
</script>

<style scoped lang="scss">
@import "./style/index.scss";
</style>
